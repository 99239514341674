<template>
  <div id="app">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h1 class="mt-5 heading-main">
            Domain for <br />
            sale
          </h1>
          <div class="container">
            <div class="row">
              <div class="col-4"><hr /></div>
              <div class="col-1"></div>
              <div class="col-4"><hr /></div>
              <div class="col-3"></div>
            </div>
            <div class="row">
              <div class="col-12">
                <p class="domain" style="text-align: left">{{ domain }}</p>
                <p class="yours" style="text-align: left">
                  this could be yours today. <br />Make us an offer
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card" style="width: 70%; margin-left: 20px">
            <div class="card-body">
              <div v-if="validCode">
                <lord-icon
                  src="https://cdn.lordicon.com/xlrvroxq.json"
                  trigger="loop"
                  colors="primary:#121331,secondary:#787ff6"
                  style="width: 150px; height: 150px"
                >
                </lord-icon>

                <p class="mt-5" style="font-size: 20px">
                  Thank you for your interest. We will be in contact shortly.
                </p>
              </div>
              <Form
                v-slot="{ errors }"
                style="text-align: left"
                v-if="!validCode"
                @submit="startVerification"
              >
                <div v-if="displayVerifyInput == false">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label"
                      >Offer (USD)</label
                    >
                    <Field
                      name="offer"
                      :rules="isRequired"
                      class="form-control inputa"
                      type="text"
                      v-model="offer"
                    />
                    <span class="err">{{ errors.offer }}</span>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Name / Company
                    </label>
                    <Field
                      name="fullname"
                      :rules="isRequired"
                      class="form-control inputa"
                      type="text"
                      v-model="fullname"
                    />
                    <span class="err">{{ errors.fullname }}</span>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label"
                      >Email</label
                    >
                    <Field
                      name="email"
                      :rules="isRequired"
                      class="form-control inputa"
                      type="text"
                      v-model="email"
                    />
                    <span class="err">{{ errors.email }}</span>
                  </div>

                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label"
                      >Mobile</label
                    >
                    <br />

                    <Field
                      id="phone"
                      name="phone"
                      :rules="isRequired"
                      class="form-control inputa"
                      type="tel"
                      v-model="mobile"
                      v-on:click="clearError"
                    />

                    <span class="err">{{ errors.phone }}</span>
                    <span class="err" v-if="phoneInvalid"
                      >Invalid phone number. Check the number and try
                      again.</span
                    >

                    <p
                      class="text-muted mt-3"
                      style="font-size: 13px"
                      v-if="displayVerifyInput == false"
                    >
                      We will never sell your personal details to anyone. Ever.
                    </p>
                  </div>
                </div>

                <div class="mb-3" v-if="displayVerifyInput == true">
                  <p class="text-center mb-5 mt-5">
                    Your one time password has been sent to:
                    {{ fullMobileNumberDisplay }}
                  </p>

                  <!-- <label for="exampleInputPassword1" class="form-label"
                    >Verify code</label> -->

                  <div class="container-flex">
                    <div class="row">
                      <div class="col-8">
                        <input
                          type="text"
                          class="form-control inputa inputa-vcode"
                          id="exampleInputPassword1"
                          v-model="vcode"
                          placeholder="Enter your 6 digit code"
                        />
                      </div>
                      <div class="col-4" style="padding: 0">
                        <button
                          type="button"
                          class="btn btn-light btn-sub-verify"
                          @click="checkVerification"
                        >
                          VERIFY
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-3"
                    style="color: red"
                    v-if="displayCodeError == true"
                  >
                    {{ codeError }}
                  </div>

                  <div class="container-flex">
                    <div class="row">
                      <div class="col-12 text-center mt-4">
                        <lord-icon
                          v-if="displayLoadVerify"
                          src="https://cdn.lordicon.com/dpinvufc.json"
                          trigger="loop"
                          colors="primary:#787ff6,secondary:#08a88a"
                          scale="34"
                          style="width: 200px; height: 200px"
                        >
                        </lord-icon>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 text-center">
                        <button
                          type="button"
                          class="btn btn-link"
                          @click="resendVerification"
                        >
                          Resend one time passcode
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <p class="text-center" style="font-size: 30px">OR</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 text-center">
                        <button
                          type="button"
                          class="btn btn-link"
                          @click="wrongNumber"
                        >
                          Wrong number?
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container-flex">
                  <div class="row">
                    <div class="col-8">
                      <button
                        v-if="displayVerifyInput == false"
                        class="btn btn-light btn-sub"
                      >
                        SUBMIT OFFER
                      </button>
                    </div>
                    <div class="col-4">
                      <lord-icon
                        v-if="displayLoad"
                        src="https://cdn.lordicon.com/dpinvufc.json"
                        trigger="loop"
                        colors="primary:#787ff6,secondary:#08a88a"
                        scale="34"
                        style="width: 100px; height: 100px"
                      >
                      </lord-icon>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import mobilePrefix from "../mobilePrefix";
import axios from "axios";
export default {
  name: "HelloWorld",
  components: {
    Field,
    Form,
  },

  data() {
    return {
      domain: "something",
      zapHook: "https://hooks.zapier.com/hooks/catch/7349379/b991bz2/",
      startVerify: "https://verify-9928.twil.io/start-verify",
      checkVerify: "https://verify-9928.twil.io/check-verify",
      mobile: "",
      displayVerifyInput: false,
      validationcode: "",
      vcode: "",
      validCode: false,
      displayCodeError: false,
      codeError: "",
      displayLoad: false,
      displayLoadVerify: false,
      phoneInvalid: false,
      fullMobileNumber: "",
      fullMobileNumberDisplay: "",
    };
  },

  created: function () {
    var currentUrl = window.location.origin;

    console.log(currentUrl);
    this.domain = currentUrl.toUpperCase();
  },
  mounted: function () {
    // on mounted call this so it modifies the dom for the mobile number input field
    mobilePrefix.initialiseIntelInput();
  },

  methods: {
    isRequired(value) {
      return value ? true : "This field is required";
    },
    // function used to set the value of phoneInvalid when on click is trigged when a phone number is modified
    clearError: function () {
      this.phoneInvalid = false;
    },
    wrongNumber: function () {
      // when the wrong number button is selected and we need to go back to the previous view
      this.mobile = "";
      this.displayVerifyInput = false;
      this.validationcode = "";
      this.vcode = "";
      this.validCode = false;

      // should find a better way to do this. the code is effectively manipulating the dom, then it needs to do it again
      // so it does something strange. in theory you should be about to call this without a timeout
      setTimeout(() => {
        mobilePrefix.initialiseIntelInput();
      }, 1);
    },
    startVerification: function () {
      // show the load circle
      this.displayLoad = true;

      // get the prefix title="Australia: +61"
      var countryTitlePrefix =
        document.getElementsByClassName("iti__selected-flag")[0].title;

      console.log(countryTitlePrefix);
      // then split the title value to get everything after the +
      var prefix = countryTitlePrefix.split("+")[1];

      setTimeout(() => {
        // add the + back to the prefix
        var auMobilePrefix = "+" + prefix;

        this.fullMobileNumberDisplay = auMobilePrefix + " " + this.mobile;
        
        var m = this;
        m.mobileNumber = "";
        m.mobileNumber = auMobilePrefix.concat(this.mobile);
        this.fullMobileNumber = m.mobileNumber;
        m.channel = "sms";
        m.locale = "en";

        // Twilio functions do not accept multipart/form-data
        const data = new URLSearchParams();
        //data.append("phone_number", m.mobileNumber);
        data.append("to", m.mobileNumber);
        data.append("channel", m.channel);
        data.append("locale", m.locale);

        // Create your own Twilio Function
        // https://www.twilio.com/console/functions/manage
        //fetch("https://verify-2896-dfs53r.twil.io/start-verify", {

        if (m.mobileNumber) {
          fetch(this.startVerify, {
            method: "POST",
            body: data,
          })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              if (json.success) {
                // show the verification input field
                this.displayVerifyInput = true;

                // hide the spinner
                this.displayLoad = false;
              } else {
                console.log(json.error);
                // invalid response from twillio. Something went wrong, check the number and try again error display
                this.phoneInvalid = true;

                // disable load icon, so it doesn't look like its loading still
                this.displayLoad = false;
              }
            })
            .catch((err) => {
              console.log(err);
              console.log("Error starting verification.");
            });
        } else {
          console.log("No number");
        }
      }, 2000);
    },
    checkVerification: function () {
      // show loader circle to indicate loading
      this.displayLoadVerify = true;

      setTimeout(() => {
        var m = this;
        // passing the full mobile number from the start function
        m.mobileNumber = this.fullMobileNumber;
        m.channel = "sms";
        m.locale = "en";

        const code = this.vcode;

        const data = new URLSearchParams();
        data.append("to", m.mobileNumber);
        data.append("verification_code", code);

        // check with regex to see that the input code is 6 digits only
        var numbers = /^[0-9]{1,6}$/;
        if (this.vcode.match(numbers)) {
          console.log("Numbers match");
          fetch(this.checkVerify, {
            method: "POST",
            body: data,
          })
            .then((response) => response.json())
            .then((json) => {
              if (json.success) {
                console.log(json.message);
                this.validCode = true;
                console.log("Succesfully verified");
                this.submitData();
              } else {
                console.log(json.message);
                this.validCode = false;
                this.displayCodeError = true;
                // show error because code is wrong
                this.codeError =
                  "Mobile validation failed. Either input the number again or validate mobile number.";
                // hide the circle loader to show that its no longer pending load
                this.displayLoadVerify = false;
                console.log("Something went wrong");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("Not matching");

          // show error
          this.displayCodeError = true;
          this.codeError = "Your code must be 6 digits";
        }
      }, 2000);
    },
    resendVerification: function () {
      setTimeout(() => {
        var m = this;
        m.mobileNumber = this.fullMobileNumber;
        m.channel = "sms";
        m.locale = "en";

        // Twilio functions do not accept multipart/form-data
        const data = new URLSearchParams();
        //data.append("phone_number", m.mobileNumber);
        data.append("to", m.mobileNumber);
        data.append("channel", m.channel);
        data.append("locale", m.locale);

        // Create your own Twilio Function
        // https://www.twilio.com/console/functions/manage
        //fetch("https://verify-2896-dfs53r.twil.io/start-verify", {

        if (m.mobileNumber) {
          fetch(this.startVerify, {
            method: "POST",
            body: data,
          })
            .then((response) => {
              return response.json();
            })
            .then((json) => {
              if (json.success) {
                // show the verification input field
                this.displayVerifyInput = true;

                // hide the spinner
                this.displayLoad = false;
              } else {
                console.log(json.error);
                // invalid response from twillio. Something went wrong, check the number and try again error display
                this.phoneInvalid = true;

                // disable load icon, so it doesn't look like its loading still
                this.displayLoad = false;
              }
            })
            .catch((err) => {
              console.log(err);
              console.log("Error starting verification.");
            });
        } else {
          console.log("No number");
        }
      }, 2000);
    },
    submitData: function () {
      // Simple POST request with a JSON body using fetch

      // date submitted
      const date = new Date().toLocaleString();

      var formData = {
        domain: this.domain,
        offer: this.offer,
        fullname: this.fullname,
        mobile: this.fullMobileNumber,
        email: this.email,
        date: date,
      };

        // eslint-disable-next-line no-unused-vars
         axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
          .post(this.zapHook, formData)
          .then(function(response) {
            console.log(response);
          });

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.domain {
  color: #787ff6;
  font-family: "Space Mono", monospace;
  margin-top: 25px;
}
.yours {
  text-transform: uppercase;
  color: #787ff6;
  font-family: "Space Mono", monospace;
  margin-top: 25px;
}
h1 {
  font-size: 80px;
  text-align: left;
}
h1 {
  color: #000;
  font-weight: 600;
  margin-left: 20px;
}
hr {
  border: 3px solid #787ff6;
  margin-right: 30px;
}
.btn-sub {
  border: 1px solid #000000;
  border-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  background-color: #ffffff;
}

.btn-sub:hover {
  color: #ffffff;
  background-color: #000000;
}

.btn-sub-verify {
  border: 1px solid #000000;
  border-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
  background-color: #ffffff;
}

.btn-sub-verify:hover {
  color: #ffffff;
  background-color: #000000;
}

.form-control {
  display: block;
  width: 100%;
  background-clip: padding-box;
  box-shadow: inset 0 1px 1px rgb(31 45 61 / 8%);
  transition: all 0.2s ease;
}

.inputa {
  padding: 0.8125rem 1.25rem;
  font-size: 1.0625rem;
  font-weight: 400;
  height: calc(1.6em + 1.625rem + 2px);
  border: 1px solid #e9ecef;
}

.card {
  min-height: 600px;
  max-height: 700px;
}

.card-body {
  margin: 2em 2em 1em 2em !important;
}

.err {
  color: red;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 90% !important;
    margin-bottom: 30px;
    min-height: 350px;
  }
  .card-body {
    margin: 0em !important;
  }
  .heading-main {
    margin-top: 0 !important;
  }
  h1 {
    font-size: 60px;
  }
  .btn-sub {
    font-size: 14px;
  }
  .inputa-vcode{
    font-size: 12px;
  }
  .btn-sub-verify{
    padding: 10px 20px 10px 20px !important;
  }
}
</style>
