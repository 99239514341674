var mobilePrefix = {
  getIp(callback) {
    fetch("https://ipinfo.io/json?token=c077678caf05fb", {
      headers: { Accept: "application/json" },
    })
      .then((resp) => resp.json())
      .catch(() => {
        return {
          country: "au",
        };
      })
      .then((resp) => callback(resp.country));
  },

  initialiseIntelInput() {
    const phoneInputField = document.querySelector("#phone");
    window.intlTelInput(phoneInputField, {
      initialCountry: "auto",
      geoIpLookup: this.getIp,
      preferredCountries: ["au", "us"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
  },
};
export default mobilePrefix